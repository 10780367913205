@use '@angular/material' as mat;

@mixin mat-table-theme($theme) {

  .mat-mdc-row {
    .visible-on-hover-only {
      opacity: 0;
      transition: 0.1s;
    }
  }

  .mat-mdc-row:hover {
    background: rgba(248, 248, 248, 0.99);

    .visible-on-hover-only {
      opacity: 1;
      transition: 0.1s;
    }
  }

  .mat-mdc-header-row {
    font-weight: bolder;
    font-size: mat.font-size(mat.get-typography-config($theme), body-1);
    color: darkgrey;
  }
}
