@mixin media($point) {
  @if $point==desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }

  @if $point==laptop {
    @media (max-width: 1024px) {
      @content;
    }
  }

  @else if $point==tablet {
    @media (max-width: 767px) {
      @content;
    }
  }

  @else if $point==mobile {
    @media (max-width: 480px) {
      @content;
    }
  }
}
