
$size-space-xl: 100px;
$size-space-l: 50px;
$size-space-m: 40px;
$size-space-s: 30px;
$size-space-xs: 20px;
$size-space-xxs: 10px;
$size-space-xxxs: 5px;

$size-space-15: 15px;
$notifications-width: 450px;
$notifications-hover-color: #f8f9fa;
$notifications-font-size-small: 14px;
$notifications-padding-small: 8px;
$notifications-padding-big: 24px;

// Colors
$color-green: #28a745;
$color-blue:  #3872C1;
$color-blue-dark:  #0061a0;
$color-danger: #dc3545;
$color-gray-light: #D3D3D3;

$dark-text: rgba(black, 0.87);
$light-text: white;
