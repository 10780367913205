@use '@angular/material' as mat;
@import "../style-utils/variables";
@import "../style-utils/mixins";

@mixin design-general-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  a,
  span.clickable,
  .link {
    color: mat.get-color-from-palette($primary, 500);
    text-decoration: none;

    &.danger {
      color: mat.get-color-from-palette($primary, warn);

      &:hover {
        color: mat.get-color-from-palette($primary, darker);
      }
    }

    &:hover {
      color: mat.get-color-from-palette($primary, darker);
      text-decoration: none;
      cursor: pointer;
    }
  }

  .warnings {
    display: flex;
    align-items: center;

    mat-icon {
      color: #ffba24;
    }
  }

  .ng-submitted {
    & * > .valid-radio-group {
      & * > label {
        color: inherit;
      }
    }
    & * > .invalid-radio-group {
      & * > label {
        color: mat.get-color-from-palette($warn, 500);
      }
    }
  }

  .organisationNameContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .organisationName {
    word-wrap: normal;
    white-space: pre-wrap;
    font-size: 1.1vw;
    line-height: 1.1vw;
  }
}
