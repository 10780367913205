@use '@angular/material' as mat;

@mixin mat-snackbar-theme($theme) {
  .success-snackbar {
    --mdc-snackbar-container-color: #19ad19;
    --mat-mdc-snack-bar-button-color: white;
    --mdc-snackbar-supporting-text-color: white;
  }

  .accent-snackbar {
    --mdc-snackbar-container-color: #af812e;
    --mat-mdc-snack-bar-button-color: white;
    --mdc-snackbar-supporting-text-color: white;
  }

  .danger-snackbar {
    --mdc-snackbar-container-color: #bd2525;
    --mat-mdc-snack-bar-button-color: white;
    --mdc-snackbar-supporting-text-color: white;
  }
}

