@use '@angular/material' as mat;

@mixin mat-list-item-theme($theme) {
  .nav-container {
    .mdc-list-item__primary-text {
      display: flex;
    }
  }
}

