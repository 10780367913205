@use '@angular/material' as mat;

@mixin design-breadcrumb($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  design-breadcrumb {
    & * .page-name {
      border-bottom: 1px solid; border-color: mat.get-color-from-palette(map-get($theme, primary), A200);
      color: mat.get-color-from-palette(map-get($theme, primary), 800);
      font-size: 1.8em;
    }
  }

  .active-users-limit {
    color: mat.get-color-from-palette(map-get($theme, primary), 800);
    font-weight: 500;
  } //TODO Dragan this should be moved to another file

}
