@import "./app/design/style-utils/variables";
@import "./app/design/style-utils/mixins";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

.CodeMirror {
  height: 500px;

  .CodeMirror-gutters {
    background-color: #002e54 !important;
  }

  .CodeMirror-linenumber {
    color: white !important;
  }
}

.missing-data {
  color: #ddd;
}

.green-color {
  color: $color-green;
}

.yellow-color {
  color: #fbd900;
}

.red-color {
  color: #bf002b;
}

.green-stroke circle {
  stroke: limegreen !important;
}

.yellow-stroke circle {
  stroke: #fbd900 !important;
}

.red-stroke circle {
  stroke: #bf002b !important;
}

example-sanctions-screening-report {
  height: 100% !important;
  display: flex;
  flex-direction: column;

  mat-dialog-content {
    flex: 1;
    max-height: none !important;
    overflow: hidden !important;
    display: flex;
  }

  mat-tab-group {
    height: 100%;

    .mat-tab-body-wrapper {
      flex: 1;

      .mat-tab-body-content {
        overflow: hidden;
      }
    }
  }
}

.login {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  height: 80vh;
  max-width: 80vw;
  background-color: white;
  border-radius: $size-space-xxxs;

  @include media(laptop) {
    max-width: 96vw;
  }

  @include media(tablet) {
    flex-direction: column;
  }

  @include media(mobile) {
    height: 100%;
    max-width: 100%;
  }

  &__image {
    flex-basis: 50%;
    position: relative;

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      filter: brightness(50%);
      border-radius: $size-space-xxxs 0 0 $size-space-xxxs;
    }

    &-content {
      padding: $size-space-s;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 1;
      position: relative;
      height: 100%;
      width: 100%;
      color: white;

      &-logo {
        width: 150px;

        a {
          img {
            width: 150px;
          }
        }
      }

      &-link {
        text-align: right;
      }

      &-text {
        text-align: center;

        @include media(mobile) {
          display: none;
        }

        h1 {
          margin-bottom: $size-space-xs;
          font-weight: bold;
        }

        p {
          width: 50%;
          margin-left: auto;
          text-align: left;
        }
      }
    }
  }

  &__form {
    padding: $size-space-s;
    display: flex;
    flex-basis: 50%;
    height: 100%;

    &-language {
      position: absolute;
      top: $size-space-xs;
      right: $size-space-xs;
      z-index: 1;
    }

    &-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-text {
        padding: $size-space-xs 0;

        div {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .back-button {
            margin-left: -($size-space-15);
          }

          h3 {
            margin: 0;
          }
        }
      }

      &__password-message {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

client-create-customer {
  mat-dialog-content {
    mat-vertical-stepper {
      .mat-step {
        mat-step-header {
          padding-left: 0;
        }

        * > .create-client__stepper-radio-right {
          margin-left: auto;
          padding: $size-space-xxs 0;
        }
      }
    }
  }
}

.stepper-container.signup-stepper {
  @include media(tablet) {
    padding: 0;
    mat-horizontal-stepper {
      .mat-horizontal-stepper-header-container {
        flex-direction: column;
        align-items: flex-start;
        white-space: pre-wrap;

        .mat-stepper-horizontal-line {
          display: none;
        }

        .mat-step-header {
          height: $size-space-l;
        }
      }

      .mat-horizontal-content-container {
        .mat-horizontal-stepper-content {
          .signup-stepper-bottom-content {
            flex-direction: column;

            &-item {
              padding: 0;
            }
          }
        }
      }
    }
  }
  @include media(mobile) {
    mat-horizontal-stepper {
      .mat-horizontal-stepper-header-container {
        .mat-horizontal-stepper-header {
          max-width: 80%;
          padding: 0 $size-space-15;
        }

        .mat-step-header {
          max-height: $size-space-m;
        }
      }

      .mat-horizontal-content-container {
        padding: 0 $size-space-15 $size-space-15;

        .mat-horizontal-stepper-content {
          * > mat-form-field {
            height: $size-space-l;
          }
        }
      }
    }
  }
}

.signup-stepper-buttons {
  @include media(mobile) {
    flex-direction: column-reverse;

    design-stepper-next-button, design-button, design-stepper-previous-button {
      width: 100%;

      button {
        width: 100%;
      }
    }

    button {
      width: 100%;
      margin-top: $size-space-xs;
      margin-right: 0 !important;
    }
  }
}

app-login-shared {
  shared-language {
    @include media(mobile) {
      color: white;
    }
  }
}


client-container {
  design-nav-item:nth-of-type(1) {
    a {
      margin: $size-space-xxs 0;
    }
  }
}

.clickable {
  cursor: pointer;
}

.risk-score-inside {
  position: absolute;
  width: $size-space-m;
  height: $size-space-m;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.paginator-loader {
  position: absolute;
  right: 600px;
  transform: translateY(15px);
}
