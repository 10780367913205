@use '@angular/material' as mat;

@mixin design-burger-button($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  $burgerAreaWidth: 32px;
  $burgerAreaHeight: 40px;
  $burgerWidth: 24px;
  $burgerBarHeight: 1px;
  $burgerBarColor: mat.get-color-from-palette($primary, A100);
  $burgerBarHoverColor: mat.get-color-from-palette($accent, 100);
  $burgerCloseAnimationDuration: 100ms;
  $burgerOpenAnimationDuration: 50ms;

  .burger-menu {
    width: $burgerAreaWidth;
    height: $burgerAreaHeight;
    cursor: pointer;

    .burger-container {
      position: absolute;
      top: 60%;
      right: 50%;

      .burger-inner {
        position: relative;
        width: $burgerWidth - 2 * $burgerBarHeight;
        border: $burgerBarHeight solid $burgerBarColor;
        transition: border-color $burgerOpenAnimationDuration ease-in $burgerCloseAnimationDuration;

        &::before {
          position: absolute;
          content: '';
          top: -6px;
          left: -$burgerBarHeight;
          width: $burgerWidth - 2 * $burgerBarHeight;
          border: $burgerBarHeight solid $burgerBarColor;
          transition: top $burgerCloseAnimationDuration ease-in $burgerOpenAnimationDuration, transform ease-in $burgerCloseAnimationDuration;
        }

        &::after {
          position: absolute;
          content: '';
          top: 4px;
          left: -$burgerBarHeight;
          width: $burgerWidth - 2 * $burgerBarHeight;
          border: $burgerBarHeight solid $burgerBarColor;
          transition: top $burgerCloseAnimationDuration ease-in $burgerOpenAnimationDuration, transform ease-in $burgerCloseAnimationDuration;
        }
      }
    }

    &.active .burger-container .burger-inner {
      border-color: transparent;
      transition: border-color ease-in $burgerOpenAnimationDuration;
    }

    &.active .burger-container .burger-inner::before {
      top: -$burgerBarHeight;
      transform: rotate(-45deg);
      transition: top ease-out $burgerCloseAnimationDuration, transform $burgerCloseAnimationDuration ease-out ($burgerOpenAnimationDuration + $burgerCloseAnimationDuration);
    }

    &.active .burger-container .burger-inner::after {
      top: -$burgerBarHeight;
      transform: rotate(45deg);
      transition: top ease-out $burgerCloseAnimationDuration, transform $burgerCloseAnimationDuration ease-out ($burgerOpenAnimationDuration + $burgerCloseAnimationDuration);
    }
  }

  .burger-menu:hover {
    .burger-inner {
      transition: none;
      border-color: $burgerBarHoverColor;
    }

    &.active .burger-inner {
      border-color: transparent;
    }

    .burger-inner::before,
    .burger-inner::after {
      border-color: $burgerBarHoverColor;
    }
  }
}
