@import "./style-utils/variables";
@import "./style-utils/mixins";

.metricity {
  .tool-tip-with-break-line {
    white-space: pre-wrap;
  }

  *:focus {
    outline: none;
  }

  .full-width {
    width: 100%;
  }

  label.mdc-label {
    margin-bottom: 0; // fix for wrong style set from bootstrap
  }

  .report-card {
    flex-basis: 60%;

    @include media(laptop) {
      flex-basis: 80%;
    }

    @include media(tablet) {
      flex-basis: 90%;
      max-width: 90%;
    }

    @include media(mobile) {
      flex-basis: auto;
      max-width: 94%;
      margin: 10vh auto 1vh auto;
      overflow: scroll;

      mat-horizontal-stepper {
        .mat-horizontal-stepper-header-container {
          flex-direction: column;
          align-items: flex-start;
          white-space: pre-wrap;

          .mat-stepper-horizontal-line {
            display: none;
          }

          .mat-step-header {
            height: $size-space-l;
            padding: 0;
          }
        }

        .mat-horizontal-content-container {
          padding: 0;

          .mat-horizontal-stepper-content {
            .report-card-form {
              * > mat-radio-group {
                flex-direction: column;
              }
            }

            * > button {
              width: 100%;
            }

            shared-choose-payment-method {
              * > img {
                display: block;
                max-width: 100%;
                object-fit: contain;
              }

              + button {
                width: 100%;
              }
            }

            .report-card-buttons {
              flex-direction: column-reverse;
              width: 100%;

              button, design-button {
                margin-left: 0;
                margin-bottom: $size-space-xxs;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .link-element-lightblue {
    * > a {
      * {
        color: #b4e1f9;
      }
    }

    a {
      * {
        color: #b4e1f9;
      }
    }

    & a {
      color: #b4e1f9;
    }
  }

  .document-rejected, .disabled-ubo-pep {
    * {
      color: #bebebe;
    }

    .mat-mdc-cell {
      @extend .link-element-lightblue;
    }

    mat-mdc-cell {
      @extend .link-element-lightblue;
    }

    .document-cell {
      @extend .link-element-lightblue;
    }
  }

  .mat-divider.mat-divider-inset {
    margin: auto;
  }

  // This styles will apply to all dropdown fields

  .mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
    line-height: 1;
    padding-top: $size-space-xxxs;
    padding-bottom: $size-space-xxxs;
    min-height: 3em;
    height: auto;
  }

  .mat-option-text {
    text-overflow: initial;
    word-break: initial;
    white-space: initial;
    padding: $size-space-xxxs 0;

    @include media(mobile) {
      font-size: $notifications-font-size-small;
    }
  }
}
