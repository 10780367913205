@use '@angular/material' as mat;

@mixin mat-dialog-theme($theme) {

  .mat-mdc-dialog-container .mat-mdc-dialog-title {
    color: mat.get-color-from-palette(map-get($theme, primary), 700);
    font-weight: bolder;
  }

  .mat-mdc-dialog-actions {
    justify-content: flex-end;
  }
}
