@use '@angular/material' as mat;

@mixin _design-toolbar-color-darker($palette) {
  background: mat.get-color-from-palette($palette, 800);
  color: mat.get-color-from-palette($palette, default-contrast);
}

@mixin _design-toolbar-color-darkest($palette) {
  background: mat.get-color-from-palette($palette, 900);
  color: mat.get-color-from-palette($palette, default-contrast);
}

@mixin mat-toolbar-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-toolbar {
    background: mat.get-theme-color($theme, background, app-bar);
    color: mat.get-theme-color($theme, foreground, text);

    &.mat-primary-darker {
      @include _design-toolbar-color-darker($primary);
    }

    &.mat-accent-darker {
      @include _design-toolbar-color-darker($accent);
    }

    &.mat-warn-darker {
      @include _design-toolbar-color-darker($warn);
    }

    &.mat-primary-darkest {
      @include _design-toolbar-color-darkest($primary);
    }

    &.mat-accent-darkest {
      @include _design-toolbar-color-darkest($accent);
    }

    &.mat-warn-darkest {
      @include _design-toolbar-color-darkest($warn);
    }

  }
}
