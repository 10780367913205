@use '@angular/material' as mat;

@mixin design-stepper-next-button($theme) {

  .mat-mdc-dialog-actions {
    design-stepper-next-button {
      margin-left: 8px;
    }
  }

}
