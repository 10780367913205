@use '@angular/material' as mat;

@mixin mat-card-theme($theme) {

  .mat-mdc-card-title {
    color: mat.get-color-from-palette(map-get($theme, primary), 800);
  }
}

// FIX for the elevation bug

.mat-mdc-card.mat-elevation-z1 {
  @include mat.elevation(1);
}

.mat-mdc-card.mat-elevation-z2 {
  @include mat.elevation(2);
}

.mat-mdc-card.mat-elevation-z3 {
  @include mat.elevation(3);
}

.mat-mdc-card.mat-elevation-z4 {
  @include mat.elevation(4);
}

.mat-mdc-card.mat-elevation-z5 {
  @include mat.elevation(5);
}

.mat-mdc-card.mat-elevation-z6 {
  @include mat.elevation(6);
}

.mat-mdc-card.mat-elevation-z7 {
  @include mat.elevation(7);
}

.mat-mdc-card.mat-elevation-z8 {
  @include mat.elevation(8);
}

.mat-mdc-card.mat-elevation-z9 {
  @include mat.elevation(9);
}

.mat-mdc-card.mat-elevation-z10 {
  @include mat.elevation(10);
}


.mat-mdc-card.mat-elevation-z11 {
  @include mat.elevation(11);
}

.mat-mdc-card.mat-elevation-z12 {
  @include mat.elevation(12);
}

.mat-mdc-card.mat-elevation-z13 {
  @include mat.elevation(13);
}

.mat-mdc-card.mat-elevation-z14 {
  @include mat.elevation(14);
}

.mat-mdc-card.mat-elevation-z15 {
  @include mat.elevation(15);
}

.mat-mdc-card.mat-elevation-z16 {
  @include mat.elevation(16);
}

.mat-mdc-card.mat-elevation-z17 {
  @include mat.elevation(17);
}

.mat-mdc-card.mat-elevation-z18 {
  @include mat.elevation(18);
}

.mat-mdc-card.mat-elevation-z19 {
  @include mat.elevation(19);
}

.mat-mdc-card.mat-elevation-z20 {
  @include mat.elevation(20);
}

.mat-mdc-card.mat-elevation-z21 {
  @include mat.elevation(21);
}

.mat-mdc-card.mat-elevation-z22 {
  @include mat.elevation(22);
}

.mat-mdc-card.mat-elevation-z23 {
  @include mat.elevation(23);
}

.mat-mdc-card.mat-elevation-z24 {
  @include mat.elevation(24);
}
