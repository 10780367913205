@use '@angular/material' as mat;
@import "../style-utils/variables";
@import "../style-utils/mixins";

@mixin design-body-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  body {
    font-family: mat.font-family(mat.get-typography-config($theme));
    font-size: mat.font-size(mat.get-typography-config($theme), body-1);
    line-height: mat.line-height(mat.get-typography-config($theme), body-1);;
    letter-spacing: mat.letter-spacing(mat.get-typography-config($theme), body-1);
    font-weight: mat.font-weight(mat.get-typography-config($theme), body-1);

    .headline-1 {
      font-family: mat.font-family(mat.get-typography-config($theme));
      font-size: mat.font-size(mat.get-typography-config($theme), headline-1);
      line-height: mat.line-height(mat.get-typography-config($theme), headline-1);;
      letter-spacing: mat.letter-spacing(mat.get-typography-config($theme), headline-1);
      font-weight: mat.font-weight(mat.get-typography-config($theme), headline-1);
    }
    .headline-2 {
      font-family: mat.font-family(mat.get-typography-config($theme));
      font-size: mat.font-size(mat.get-typography-config($theme), headline-2);
      line-height: mat.line-height(mat.get-typography-config($theme), headline-2);;
      letter-spacing: mat.letter-spacing(mat.get-typography-config($theme), headline-2);
      font-weight: mat.font-weight(mat.get-typography-config($theme), headline-2);
    }
    .headline-3 {
      font-family: mat.font-family(mat.get-typography-config($theme));
      font-size: mat.font-size(mat.get-typography-config($theme), headline-3);
      line-height: mat.line-height(mat.get-typography-config($theme), headline-3);;
      letter-spacing: mat.letter-spacing(mat.get-typography-config($theme), headline-3);
      font-weight: mat.font-weight(mat.get-typography-config($theme), headline-3);
    }
    .headline-4 {
      font-family: mat.font-family(mat.get-typography-config($theme));
      font-size: mat.font-size(mat.get-typography-config($theme), headline-4);
      line-height: mat.line-height(mat.get-typography-config($theme), headline-4);;
      letter-spacing: mat.letter-spacing(mat.get-typography-config($theme), headline-4);
      font-weight: mat.font-weight(mat.get-typography-config($theme), headline-4);
    }
    .headline-5 {
      font-family: mat.font-family(mat.get-typography-config($theme));
      font-size: mat.font-size(mat.get-typography-config($theme), headline-5);
      line-height: mat.line-height(mat.get-typography-config($theme), headline-5);;
      letter-spacing: mat.letter-spacing(mat.get-typography-config($theme), headline-5);
      font-weight: mat.font-weight(mat.get-typography-config($theme), headline-5);
    }
    .headline-6 {
      font-family: mat.font-family(mat.get-typography-config($theme));
      font-size: mat.font-size(mat.get-typography-config($theme), headline-6);
      line-height: mat.line-height(mat.get-typography-config($theme), headline-6);;
      letter-spacing: mat.letter-spacing(mat.get-typography-config($theme), headline-6);
      font-weight: mat.font-weight(mat.get-typography-config($theme), headline-6);
    }
  }

}
