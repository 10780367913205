@use '@angular/material' as mat;

@mixin notifications-menu($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .notifications-menu {

    &.mat-mdc-menu-panel {
      max-width: none !important;
      width: $notifications-width !important;

      .mat-mdc-menu-content {
        max-height: 80vh;
        overflow-y: auto;
      }

      .mat-expansion-panel {

        &.mat-expanded {
          margin: 0 !important;
        }

        .mat-expansion-panel-header {
          background-color: transparent;

          &:hover {
            background-color: $notifications-hover-color;

            & + .mat-expansion-panel-content {
              background-color: $notifications-hover-color;
            }
          }

          .mat-expansion-indicator {
            display: none;
          }

          &-title {
            display: flex;
            justify-content: space-between;
            margin: 0;
          }

          .notifications__accordion-title-link {
            font-weight: normal;
            font-size: $notifications-font-size-small;

            fa-icon {
              margin-left: $size-space-xxxs;
            }
          }

          .notifications__accordion-title {
            margin-bottom: 0;
            display: flex;
            align-items: center;

            &-number {
              width: $size-space-xs;
              min-width: $size-space-xs;
              height: $size-space-xs;
              border-radius: 50%;
              background-color: mat.get-color-from-palette($warn, 500);
              color: #ffffff;
              text-align: center;
              display: block;
              vertical-align: middle;
              margin-right: $size-space-xxs;
            }
          }
        }

        &-body {
          padding: $notifications-padding-small $notifications-padding-big;
          width: 100%;
        }
      }

      .mat-expansion-panel-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mat-expansion-panel-body {
          padding: 0;
        }

        .notifications__accordion-content {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .notification-body {
            padding-left: 24px;

            .notification-text {
              font-weight: bold;
              color: #000;
            }
          }

          .notification-read {
            margin-left: auto;
            padding: 0 24px;
          }

          span.w-100.h-100 {
            display: flex;
            align-items: center;
            padding: 16px 0;
          }

          &:hover {
            background-color: #f8f9fa;
          }

          fa-icon {
            margin-right: auto;
            margin-left: 24px;
          }

          &-icon-warn {
            color: mat.get-color-from-palette($warn, 500);
          }

          &-icon-info {
            color: mat.get-color-from-palette($primary, 500);
          }

          &-icon-success {
            color: $color-green;
          }

          &-link {
            @extend .notifications__accordion-title-link;
            border-bottom: 1px solid transparent;
            margin-right: 24px;

            &:hover {
              border-bottom: 1px solid;
            }

            p {
              margin: 0;
            }

            fa-icon {
              margin-left: $size-space-xxxs;
            }
          }

          a.notifications__accordion-content-text {
            padding: 16px 0;
          }
        }
      }
    }

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
      box-shadow: none;
      border: none;

      &:hover {
        background-color: transparent;
      }
    }

    .notifications {
      &__header {
        display: flex;
        justify-content: space-between;
        font-size: $notifications-font-size-small;;
        padding: $size-space-xxs;

        &-title {
          font-size: $notifications-font-size-small;;
          font-weight: bolder;
        }
      }
    }
  }
}
