@use '@angular/material' as mat;

@mixin mat-sidenav-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  $item-color: mat.get-color-from-palette($primary, A100);
  $item-color-hover: mat.get-color-from-palette($accent, 100);
  $item-color-selected: mat.get-color-from-palette($accent, 200);

  $drawer-container-background-color: mat.get-color-from-palette($primary, 900);
  $drawer-push-background-color: mat.get-color-from-palette($primary, 900);
  $drawer-side-border: solid 1px  mat.get-theme-color($theme, foreground, divider);

  .mat-drawer-inner-container {
    background-color: $drawer-container-background-color;
  }

  .sidenav-container {
    height: 100%;
  }

  .sidenav {
    position: absolute;
    left: 0;
    height: 100%;

    design-burger-button {
      position: absolute;
      right: 0;
      z-index: 999;
      padding-top: 5px;
      padding-right: 20px;
    }

    .mat-divider {
      margin-bottom: 20px;
    }
    .mat-mdc-subheader {
      font-size: mat.font-size(mat.get-typography-config($theme), body-1);
      font-style: italic;
      color: lightgrey;

      border-bottom: solid 2px lightgrey;
    }

    // in case some elements with this class initialise after the 'ngAfterViewInit' method in 'sidenav.directive.ts'
    .sidenav-content-hide {
      visibility: hidden;
      white-space: nowrap;
    }

    // hide horizontal scrollbar here
    mat-nav-list {
      ::-webkit-scrollbar {
        height: 0;
        width: 0;
        display: none;
      }
    }
    ::-webkit-scrollbar-thumb:horizontal {
      display: none;
    }

    @keyframes sidenav_text_animation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .mdc-list-item {
      margin-top: 2px;
    }

    .mdc-list-item__primary-text {
      color: $item-color;
    }

    .selected {
      .mdc-list-item__primary-text {
        color: $item-color-selected;
      }
    }

    .mat-mdc-list-item-interactive:hover {
      background-color: $drawer-push-background-color;
      .mdc-list-item__primary-text {
        color: $item-color-hover;
      }
    }

    // Hide the scrollbar when the side navigation is closed/not hovered
    & {
      @supports (-moz-appearance:none) { /* Firefox */
        scrollbar-width: none;
        scrollbar-color: transparent transparent;
      }
      @supports (-ms-overflow-style: scrollbar) { /* Internet Explorer 10+, Edge */
        -ms-overflow-style: none;
      }
      @supports (-webkit-box-align: center) { /* Safari, Chrome and Opera */
        ::-webkit-scrollbar {
          display: block;  /* Safari, Chrome and Opera */
          width: 8px;
          visibility: hidden;
        }
      }
    }
  }

  .sidenav-active {
    @extend .sidenav;

    .sidenav-content-hide {
      visibility: visible;
      word-wrap: normal;
      white-space: pre-wrap;
      animation-direction: normal;
      animation-name: sidenav_text_animation;
      animation-duration: 350ms;
    }
  }
  .sidenav-active:hover {
    & {
      @supports (-moz-appearance:none) { /* Firefox */
        scrollbar-width: thin;
        scrollbar-color: mat.get-color-from-palette($primary, 100) mat.get-color-from-palette($primary, 700);
      }
      @supports (-ms-overflow-style: scrollbar) { /* Internet Explorer 10+, Edge */
        -ms-overflow-style: scrollbar;
      }
      @supports (-webkit-box-align: center) { /* Safari, Chrome and Opera */
        ::-webkit-scrollbar {
          visibility: visible;
        }
        /* Track */
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px (mat.get-color-from-palette($primary, 100));
          border-radius: 3px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: mat.get-color-from-palette($primary, 700);
          border-radius: 3px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: rgba(mat.get-color-from-palette($primary, 700), 0.8);
        }
      }
    }
  }

  .mat-drawer-side {
    border-right: $drawer-side-border;

    &.mat-drawer-end {
      border-left: $drawer-side-border;
      border-right: none;
    }
  }

  [dir='rtl'] .mat-drawer-side {
    border-left: $drawer-side-border;
    border-right: none;

    &.mat-drawer-end {
      border-left: none;
      border-right: $drawer-side-border;
    }
  }

  .mat-drawer-backdrop.mat-drawer-shown {
    $opacity: 0.6;
    $backdrop-color: rgba(mat.get-theme-color($theme, background, card),$opacity);

    @if (type-of($backdrop-color) == color) {
      // We use invert() here to have the darken the background color expected to be used. If the
      // background is light, we use a dark backdrop. If the background is dark,
      // we use a light backdrop.
      background-color: invert($backdrop-color);
    }
    @else {
      // If we couldn't resolve the backdrop color to a color value, fall back to using
      // `opacity` to make it opaque since its end value could be a solid color.
      background-color: $backdrop-color;
      opacity: $opacity;
    }
  }
}
